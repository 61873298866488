<template>
  <div class="mx-auto container container--fluid">
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-toolbar flat dense color="white">
      <v-toolbar-title>Whats App Template Category Mapping</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
    </v-toolbar>
    <v-divider class="mx-4"></v-divider>
    <v-app id="inspire" class="">
      <template v-if="load == true">
        <v-data-table :headers="headers" :items="whatsapp_mapping_list" class="elevation-1" :search="search">
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details>
              </v-text-field>
              <v-spacer></v-spacer>
              <span style="width: 20px"></span>
              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on }">
                  <v-btn color="indigo darken-4" dark class="mb-2" v-on="on" @click="iseditable = !iseditable">Add New
                    Whats App Template Category Mapping</v-btn>
                </template>
                <v-card>
                  <v-form>
                    <v-card-title>
                      <span>{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <!-- <v-col cols="12" sm="12" md="12" v-if='editedIndex == -1'>
                                                <v-autocomplete :items="org_list" class="pt-2 pb-1" v-model="editedItem.organization" label="Select Organization" :rules="[v => !!v || 'required']" persistent-hint
                                                        :hint="editedIndex==-1?'For example :: VIT,VIIT':'For example :: VIT,VIIT'"></v-autocomplete>
                                            </v-col>
                                             <v-col cols="12" v-if='editedIndex != -1'>Organization
                                                <v-select dense class="pt-2 pb-1" v-model="editedItem.organization" :items="org_list" item-value="id" persistent-hint  :error="errorMsg.organization?true:false" :error-messages="errorMsg.organization?'Required':''" persistent-hint
                                                        :hint="editedIndex!=-1?'For example :: VIT,VIIT':'For example :: VIT,VIIT'"></v-select>
                                            </v-col> -->

                        <v-col cols="12" sm="12" md="12" v-if="editedIndex == -1">
                          <label> Select WhatsApp Template<span class="required"
                              :rules="[(v) => !!v || 'required']">*</span>
                          </label>
                          <v-autocomplete class="pt-2 pb-1" :items="template_list" v-model="editedItem.whatsapptemplate"
                            :rules="[(v) => !!v || 'required']" persistent-hint></v-autocomplete>
                        </v-col>
                        <v-col cols="12" v-if="editedIndex != -1">
                          <label> WhatsApp Template<span class="required" :rules="[(v) => !!v || 'required']">*</span>
                          </label>
                          <v-select dense v-model="editedItem.whatsapptemplate" :items="template_list" item-value="id"
                            persistent-hint></v-select>
                        </v-col>

                        <v-col cols="12" sm="12" md="12" v-if="editedIndex == -1">
                          <label> Select WhatsApp Template Category<span class="required"
                              :rules="[(v) => !!v || 'required']">*</span>
                          </label>

                          <v-autocomplete class="pt-2 pb-1" :items="category_list"
                            v-model="editedItem.whatapptemplatecategory" :rules="[(v) => !!v || 'required']"
                            item-text="name" item-value="id" persistent-hint></v-autocomplete>
                        </v-col>
                        <v-col cols="12" v-if="editedIndex != -1"> <label> WhatsApp Template Category<span
                              class="required" :rules="[(v) => !!v || 'required']">*</span>
                          </label>
                          <v-select item-text="name" item-value="id" dense v-model="editedItem.whatapptemplatecategory"
                            :items="category_list" persistent-hint></v-select>
                        </v-col>
                        <!-- <v-col cols="12" v-if='editedIndex != -1'>WhatsApp Template Category
                                                <v-select dense v-model="editedItem.whatapptemplatecategory" :items="category_list" item-value="id" persistent-hint
                                                    :hint="editedIndex!=-1?'For example ::Computer Engineering,Information Technology':'For example ::Computer Engineering,Information Technology'"></v-select>
                                            </v-col>  -->

                        <v-col>
                          <input type="hidden" v-model="editedItem.id" name="id" />
                          <!-- <v-hidden v-model="editedItem.id" label="id"></v-hidden> -->
                        </v-col>
                        <v-row>
                          <v-col cols="6" sm="3" md="2">
                            <v-checkbox v-model="editedItem.isactive" color="success" label="isActive?"></v-checkbox>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" outlined text @click="close">Cancel</v-btn>
                      <v-btn color="primary darken-1" dark @click="save" :disabled="valid">Save</v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.isactive="{ item }">
            <v-switch v-model="item.isactive" @change="activeswitch(item)"
              :labels="{ checked: 'On', unchecked: 'Off' }"></v-switch>
          </template>
          <!-- <template v-slot:item.isactive="{ item }">
                        <span v-if="item.isactive">
                        <v-icon  class="mr-2  v-icon-size" color="success">mdi-check-circle-outline</v-icon>
                        </span>
                        <span v-else>
                        <v-icon  class="mr-2  v-icon-size" color="error">mdi-close-circle-outline</v-icon>
                        </span>
                    </template> -->
          <template v-slot:item.action="{ item }">
            <div>
              <v-avatar style="" size="25" class="edit-avatar">
                <v-icon class="edit-v-icon mr-2" @click="editItem(item)">edit</v-icon>
              </v-avatar>
              <v-btn text>
                <v-icon color="red" @click="deletefun(item.id)">
                  mdi-delete</v-icon></v-btn>
            </div>
          </template>
        </v-data-table>
      </template>
    </v-app>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    valid: false,
    overlay: false,
    snackbar_msg: "",
    color: "",
    snackbar: false,
    iseditable: false,
    load: false,
    dialog: false,
    whatsapp_mapping_list: null,
    org_list: null,
    prgtype_list: null,
    category_list: null,
    template_list: null,
    errorMsg: {
      id: false,
    },
    search: "",
    headers: [
      {
        text: "Sr. No",
        sortable: true,
        value: "sr_no",
      },
      {
        text: "Organization",
        sortable: true,
        value: "organization",
      },
      {
        text: "WhatsApp Template",
        sortable: true,
        value: "whatsapptemplate",
      },
      {
        text: "whatapp Template category",
        sortable: true,
        value: "whatapptemplatecategory",
      },
      {
        text: "Active",
        sortable: true,
        value: "isactive",
      },
      {
        text: "Actions",
        value: "action",
        sortable: false,
      },
    ],

    editedIndex: -1,
    editedItem: {
      // id: 0,
      // name: '',

      // organization: '',

      //  isactive: true,
      whatsapptemplate: "",
      whatapptemplatecategory: "",
    },
    defaultItem: {
      // id: 0,
      // name: '',

      // organization: '',

      // isactive: true,
      whatsapptemplate: "",
      whatapptemplatecategory: "",
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "New Whats App Template Category Mapping"
        : "Edit Program";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    overlay(val) {
      val = () => {
        this.overlay = false;
      };
    },
  },
  mounted() {
    this.onload();
  },

  methods: {
    onload() {
      axios
        .post("/AdminMaster/add_Whats_App_Template_Category_Mapping")
        .then((res) => {
          if (res.data.msg == "200") {
            if ((this.load = true)) {
              this.overlay = false;
              this.whatsapp_mapping_list = res.data.whatsapp_mapping_list;
              this.template_list = res.data.template_list;
              this.category_list = res.data.category_list;
              this.load = true;
            }
          } else {
            window.console.log(res.data.msg);
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          // window.console.log(error);
        });
    },
    initializeErrorMsgFalse() {
      this.errorMsg.id = false;
    },
    editItem(item) {
      // console.log(item);
      this.iseditable = false;
      this.editedIndex = this.whatsapp_mapping_list.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.whatapptemplatecategory = item.whatapptemplatecategory_id;
      this.dialog = true;
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.initializeErrorMsgFalse();
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      // console.log(this.editedItem)
      if (this.editedItem.whatsapptemplate == "") {
        this.showSnackbar("red", "please Select WhatsApp Template");
      } else if (this.editedItem.whatapptemplatecategory == "") {
        this.showSnackbar("red", "please Enter Whatapp Template Category");
      } else {
        if (this.editedIndex > -1) {
          //  console.log("edit.." + this.editedItem);
          this.iseditable = false;
          axios
            .post(
              "/AdminMaster/edit_Whats_App_Template_Category_Mapping",
              this.editedItem
            )
            .then((res) => {
              if (res.data.status.code == "SUCCESS") {
                Object.assign(
                  this.whatsapp_mapping_list[this.editedIndex],
                  this.editedItem
                );
                this.showSnackbar("#4caf50", "Program Updated Successfully..."); // show snackbar on success
              } else if (res.data.status.code == "NA") {
                //alert(res.data.status.message)
                this.showSnackbar(
                  "#b71c1c",
                  "WhatsApp Template Already Present!!!"
                ); // show snackbar on error
              }
              this.onload();
            })
            .catch((error) => {
              this.showSnackbar(
                "#b71c1c",
                "Please select WhatsApp Template categort"
              ); // show snackbar
              // window.console.log(error);
            });
        } else {
          axios
            .post(
              "/AdminMaster/save_Whats_App_Template_Category_Mapping",
              this.editedItem
            )
            .then((res) => {
              if (res.data.status.code == "SUCCESS") {
                this.whatsapp_mapping_list.push(this.editedItem);
                this.showSnackbar(
                  "#4caf50",
                  "New Whats App Template Category Added Successfully!!!"
                ); // show snackbar on success
              } else if (res.data.status.code == "NA") {
                //alert(res.data.status.message)
                this.showSnackbar(
                  "#b71c1c",
                  "Whats App Template Category Already Present!!!"
                ); // show snackbar on error
              }
              this.onload();
            })
            .catch((error) => {
              window.console.log(error);
            });
        }
        this.close();
      }
    },
    deletefun(item) {
      const data = {
        deleteitem: item,
      };
      if (confirm("Do you really want to delete?")) {
        axios
          .post("/AdminMaster/delete_Whats_App_Template_Category_Mapping", data)
          .then((res) => {
            if (res.data.msg == "200") {
              // console.log("success");
              this.showSnackbar("#4caf50", "Deleted Successfully...");
              this.onload();
            } else {
              this.showSnackbar("#b71c1c", res.data.msg);
              //error
            }
            this.onload();
          })
          .catch((error) => {
            window.console.log(error);
          });
        this.close();
      }
    },
    activeswitch(item) {
      //console.log("abc");

      const data = {
        deleteitem: item.id,
      };
      //console.log(item.id);

      axios
        .post("/AdminMaster/active_Whats_App_Template_Category_Mapping", data)
        .then((res) => {
          //   console.log("res.data");
          //   console.log(res.data);
          if (res.data.msg == "200") {
            // console.log("res.data");
            // console.log(res.data);
            // console.log("success");
            this.showSnackbar("#4caf50", res.data.message);
            this.onLoad();
          } else {
            // console.log("fail");
            this.showSnackbar("#b71c1c", res.data.msg);
          }
        })
        .catch((error) => {
          window.console.log(error);
        });
      this.close();
    },
    isValidated() {
      if (this.editedItem.id) {
        return true;
      } else {
        if (!this.editedItem.id) {
          this.errorMsg.id = true;
        }

        return false;
      }
    }, // .....end of isValidated()
  },
};
</script>
<style scoped>
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}

.v-icon-size {
  font-size: 20px;
}

.edit-avatar {
  transition: 0.3s;
  border: 1px solid #b0bec5;
}

.edit-avatar:hover {
  background-color: #b0bec5;
}

.edit-v-icon {
  padding-left: 6px;
  font-size: 15px;
}

.required {
  color: red;
  font-size: 14px;
  font-style: bold;
}

.edit-v-icon:hover {
  color: white;
}
</style>
